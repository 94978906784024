<template>
    <dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col lg="3">
                        <v-rating
                            class="justify-content-start"
                            icon-label="Price scale"
                            v-model="venue.price_scale"
                            color="#85bb65"
                            full-icon="mdi-currency-usd"
                            empty-icon="mdi-currency-usd"
                            background-color="#9a9a9a"
                        ></v-rating>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.venues.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text v-if="!loading">
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-text>
                                <v-form>
                                    <v-row v-if="venueUser">
                                        <v-col lg="4">
                                            <v-select
                                                :items="pricePlansFiltered"
                                                label="Price plan"
                                                item-text="name"
                                                item-value="id"
                                                outlined
                                                v-model="pricePlan"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col lg="3">
                                            <v-text-field
                                                outlined
                                                readonly
                                                v-model="userName"
                                                label="User"
                                            />
                                        </v-col>

                                        <v-col lg="3">
                                            <v-text-field
                                                outlined
                                                v-model="venue.name"
                                                label="Name"
                                            />
                                        </v-col>

                                        <v-col lg="3">
                                            <city-selector
                                                v-if="!loading"
                                                return-object
                                                v-model="venue.city"
                                                outlined
                                                hide-details="auto"
                                            />
                                        </v-col>

                                        <v-col lg="2">
                                            <v-text-field
                                                outlined
                                                v-model="venue.address"
                                                label="Address"
                                            />
                                        </v-col>
                                        <v-col lg="1">
                                            <v-text-field
                                                outlined
                                                v-model="venue.zip"
                                                label="Zip Code"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                outlined
                                                v-model="venue['phone_number']"
                                                label="Phone"
                                            />
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                outlined
                                                v-model="venue.call_to_action_text"
                                                label="Call to action text"
                                            />
                                        </v-col>

                                        <v-col>
                                            <v-text-field
                                                outlined
                                                v-model="venue.call_to_action_url"
                                                label="Call to action URL"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                outlined
                                                v-model="venue['website_url']"
                                                label="Website URL"
                                            />
                                        </v-col>

                                        <v-col>
                                            <v-text-field
                                                outlined
                                                v-model="venue.custom_calendar_url"
                                                label="Custom calendar URL"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-textarea
                                                v-model="venue.description"
                                                outlined
                                                label="Description"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col lg="2">
                                            <v-btn color="success" @click="saveVenue">
                                                Save
                                            </v-btn>
                                        </v-col>
                                        <v-col lg="2" class="text-right">
                                            <v-btn :to="{name: 'venue.public', params: {slug: venue.slug}}">
                                                <v-icon>mdi-eye</v-icon>
                                                View
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </dashboard-layout>
</template>

<script>
import CitySelector from "@/components/CitySelector";
import Venue from "@/models/Venue";
import DashboardLayout from "@/layouts/DashboardLayout";
import User from "@/models/User";
import PricePlan from "@/models/PricePlan";

export default {
    name: "venues-show",
    components: {DashboardLayout, CitySelector},
    computed: {
        userName: {
            get() {
                return this.venue.user ? this.venue.user.name : ''
            },
            set() {
            }
        },
        pricePlansFiltered() {
            return this.pricePlans.filter(p => p.name.toLowerCase().startsWith('venue'))
        },
    },
    data: function () {
        return {
            venue: {},
            loading: false,
            pricePlans: [],
            pricePlan: null,
            venueUser: null
        }
    },
    async mounted() {
        this.loading = true

        this.venue = await Venue.with(['city', 'user']).find(this.$route.params.id)

        if (this.venue.user_id) {
            await this.loadVenueUser()
        }

        this.pricePlans = await PricePlan.custom('/price-plans/public').get()

        this.loading = false
    },
    methods: {
        saveVenue() {
            this.venue.save()

            if (this.pricePlan && this.pricePlan !== parseInt(this.venueUser['price_plan_id'])) {
                this.venueUser.price_plan_id = this.pricePlan
                this.venueUser.save()
            }
        },

        async loadVenueUser() {
            this.venueUser = await User.find(this.venue.user_id)

            if (this.venueUser && this.venueUser.price_plan_id) {
                this.pricePlan = parseInt(this.venueUser.price_plan_id)
            }
        }
    },
    watch: {
        "venue.city": function (val) {
            this.venue.city_id = val.id
        }
    }
}
</script>

<style scoped>

</style>
